import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./Sidebar.css";
import logo from "../../static/images/spiro-logo.png";
import streamline from "../../static/images/streamline-icon-pie-line-graph-140-x-140.png";
import streamsingle from "../../static/images/streamline-icon-single-neutral-profile-picture-140-x-140-copy.png";
import stream from "../../static/images/streamline-icon-calendar-140-x-140.png";
import streambusiness from "../../static/images/streamline-icon-business-deal-handshake-140-x-140.png";

const Sidebar = () => {
  const [sections] = useState([
    {
      title: "TBD",
      Url: "/dashboard",
      id: 1,
      img: stream,
      count: 4,
    },
    {
      title: "TBD",
      Url: "/dashboard",
      id: 2,
      img: streambusiness,
    },
    {
      title: "TBD",
      Url: "/dashboard",
      id: 3,
      img: streamsingle,
    },
    {
      title: "TBD",
      Url: "/dashboard",
      id: 4,
      img: streamline,
    },
  ]);

  return (
    <div class="flex flex-col items-center w-16 pb-4 overflow-auto border-r border-gray-300">
      <a
        class="flex items-center justify-center flex-shrink-0 w-full h-16 "
        href="#"
      >
        <img src={logo} alt="Logo" />
      </a>

      {sections.map((section) => (
        <NavLink to={section.Url}>
          <a
            key={section.id}
            class="flex items-center justify-center flex-shrink-0 w-10 h-10 mt-4 rounded hover:bg-gray-300"
            href="#"
          >
            <svg
              class="w-5 h-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
              />
            </svg>
          </a>
        </NavLink>
      ))}
    </div>
  );
};

export default Sidebar;
