import axios from 'axios';
import * as actionTypes from './actionTypes';
import {loader} from './auth';

// User Get Data
export const changeConfiguration = response => {
    return {
        type: actionTypes.CHANGE_CONFIGURATION,
        payload: response
    }
}

export const changeConfigurationData = (charger,connectorid,data) => {
    const token = localStorage.getItem('token');
    return dispatch => {
        var instance = axios.create({
            method: 'post',
            timeout: 60000,
            headers: { 'Authorization': 'Bearer ' + token },
            xsrfHeaderName: "X-CSRFToken"
        });
          instance.post(`${actionTypes.BASE_URL}/api/fast-charger/v1/change/configuration/charger/${charger}/connector/${connectorid}`, {
            key:data['key'], 
            value: data['value']
        })
            .then(response => {
                dispatch(loader(false))
                if(response.data.message==="No record found."){
                    dispatch(changeConfiguration(true));
                }else{
                    dispatch(changeConfiguration(true));
                }
                    
            })
            .catch(error => {
                dispatch(loader(false));
                dispatch(changeConfiguration(false));
                console.log('Error : ', error.message) 

            })
    }
}
