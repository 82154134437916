import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Loader from "../Loader/Loader";
import * as actions from "../../store/actions/auth";
import { useParams } from "react-router-dom";
import RealTimeLineChart from "../../component/LineChart";
import DialGauge from "../../component/Gauge";
import { getConnectorOnLoad } from "../../store/actions/getConnector";
import Transaction from "../Transaction/transaction";
import Modal from "../../component/Modal";
import SweetAlert from 'react-bootstrap-sweetalert';
import { sendStarTransaction } from '../../store/actions/startTransaction';
import { getTransactionOnLoad } from "../../store/actions/getRemoteTransaction";
import ChangeAvailabilityModal from "../../component/ChangeAvailabilityModal";
import ChangeConfigModal from "../../component/ChangeConfigModal";
import GetConfigModal from "../../component/GetConfigModal";
import UnlockModal from "../../component/UnlockModal";
import SendPaymentModal from "../../component/SendPaymentModal";
import { changeAvailability } from "../../store/actions/changeAvailability";
import { changeConfiguration } from "../../store/actions/chanageConfiguration";
import { getConfig } from "../../store/actions/getConfig";
import { sendPayment } from "../../store/actions/send_payment";
import { unlockConnector } from "../../store/actions/unlockConnector";
import { getCpDataOnLoad } from "../../store/actions/cp";
import { sendStopTransaction } from "../../store/actions/stopTransaction";
const Connector = ({
  loader_id,
  loading,
  cpdata,
  chargingDetail,
  getConnectorOnLoad,
  connectordetail,
  metervaluedata,
  getCpDataOnLoad,
  updateSendStarTransactionValue,
  startTransactionvalue,
  getTransactionOnLoad,
  updateSendStopTransactionValue,
  stopTransactionvalue,
  updateChangeAvailabilityValue,
  updateChangeConfigValue,
  updateGetConfigValue,
  updateSendPaymentValue,
  updateUnlockValue,
  changeConfigurationValue,
  changeAvailabilityValue,
  sendPaymentValue,
  getConfigValue,
  unlockConnectorValue
  
}) => {
  const { chargerID, connectorID } = useParams() || null;
  const [value, setValue] = useState(null);
  const [showGauge, setShowGauge] = useState(false);
  const [current, setCurrent] = useState({
    CurrentVal: null,
    timestamp_current: null,
  });
  const [voltage, setVoltage] = useState({
    VoltageVal: null,
    timestamp_voltage: null,
  });
  const { CurrentVal, timestamp_current } = current;
  const { VoltageVal, timestamp_voltage } = voltage;
  
  const [currentSoc, setCurrentSoc] = useState(connectordetail && connectordetail.current_soc || 0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenAvailibility, setIsModalOpenAvailibility] = useState(false);
  const [isModalOpenChangeConfig, setIsModalOpenChangeConfig] = useState(false);
  const [isModalOpenGetConfig, setIsModalOpenGetConfig] = useState(false);
  const [isModalOpenUnlock, setIsModalOpenUnlock] = useState(false);
  const [isModalOpenPayment, setIsModalOpenPayment] = useState(false);
  
  const closeModal = () => setIsModalOpen(false);
  const openModal = () => setIsModalOpen(true);
  const closeModalAvailibility = () => setIsModalOpenAvailibility(false);
  const openModalAvailibility = () => setIsModalOpenAvailibility(true);
  const closeModalChangeConfig = () => setIsModalOpenChangeConfig(false);
  const openModalChangeConfig = () => setIsModalOpenChangeConfig(true);
  const closeModalGetConfig = () => setIsModalOpenGetConfig(false);
  const openModalGetConfig = () => setIsModalOpenGetConfig(true);
  const closeModalUnlock = () => setIsModalOpenUnlock(false);
  const openModalUnlock = () => setIsModalOpenUnlock(true);
  const closeModalPayment = () => setIsModalOpenPayment(false);
  const openModalPayment = () => setIsModalOpenPayment(true);
  
  const [message, setMessage] = useState('');
  const [isShowMask, setShowMask] = useState(false);
  
  const onCancel = () => {
    setShowMask(false);
    updateSendStarTransactionValue(null);
    updateSendStopTransactionValue(null);  
    updateChangeAvailabilityValue(null);
    updateChangeConfigValue(null);
    updateGetConfigValue(null);
    updateUnlockValue(null);
    updateSendPaymentValue(null);
    setMessage('')
  };



  useEffect(() => {
    debugger
    if (startTransactionvalue === true) {
      setMessage('Remote Trasaction Command Sent Successfully!');
      setShowMask(true);
      getTransactionOnLoad(chargerID, connectorID);
      // sendStarTransaction(null)
    } else if (startTransactionvalue === false) {
      setMessage('Failed: ');
      setShowMask(true);
      // sendStarTransaction(null)
    }else if (stopTransactionvalue===true){
      setMessage('Stop Transaction Command Sent Successfully!: ');
      setShowMask(true);
    }else if (stopTransactionvalue===false){
      setMessage('Failed: ');
      setShowMask(true);
    }else if (getConfigValue===true){
      setMessage('Get Configuration Command Sent Successfully!: ');
      setShowMask(true);
    }else if (getConfigValue===false){
      setMessage('Failed: ');
      setShowMask(true);
    }else if (sendPaymentValue===true){
      setMessage('Payment Command Sent Successfully!: ');
      setShowMask(true);
    }else if (sendPaymentValue===false){
      setMessage('Failed: ');
      setShowMask(true);
    }else if (changeAvailabilityValue===true){
      setMessage('Availability Changed Command Sent Successfully! ');
      setShowMask(true);
    }else if (changeAvailabilityValue===false){
      setMessage('Failed: ');
      setShowMask(true);
    }else if (changeConfigurationValue===true){
      setMessage('Configuration Changed Command Sent Successfully! ');
      setShowMask(true);
    }else if (changeConfigurationValue===false){
      setMessage('Failed: ');
      setShowMask(true);
    }else if (unlockConnectorValue===true){
      setMessage('Connector Status Changed Command Sent Successfully! ');
      setShowMask(true);
    }else if (unlockConnectorValue===false){
      setMessage('Failed: ');
      setShowMask(true);
    }
  }, [getConfigValue,sendPaymentValue,changeAvailabilityValue,changeConfigurationValue,unlockConnectorValue,startTransactionvalue,stopTransactionvalue]);

  useEffect(() => {
    loader_id(true);
    getCpDataOnLoad();
    getConnectorOnLoad(chargerID, connectorID);
    const intervalId = setInterval(() => {
      getCpDataOnLoad();
      getConnectorOnLoad(chargerID, connectorID);
    }, 1000); // 10000 milliseconds = 10 seconds

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [getConnectorOnLoad]);

  useEffect(() => {
    if (Array.isArray(metervaluedata)) {
      metervaluedata.forEach((element) => {
        if (Array.isArray(element?.data)) {
          element?.data.forEach((inner) => {
            if (inner?.measurand === "SoC") {
              setValue(inner.value);
            } else if (
              inner?.measurand === "Current.Import" &&
              inner?.phase === "L1"
            ) {
              console.log(inner.value, inner.measurand,);
              setCurrent((prevCurrent) => ({
                ...prevCurrent,
                CurrentVal: inner.value,
                timestamp_current:
                  element?.started_at || prevCurrent.started_at,
              }));
            } else if (
              inner?.measurand === "Voltage" &&
              inner?.phase === "L1-N"
            ) {
              setVoltage((prevVoltage) => ({
                ...prevVoltage,
                VoltageVal: inner.value,
                timestamp_voltage:
                  element?.started_at || prevVoltage.started_at,
              }));
            }
          });
        }
      });
    }
  }, [metervaluedata]);

  useEffect(() => {
    if (connectordetail) {
      // Update showGauge and currentSoc based on connectordetail
      const status = connectordetail.status;
      setCurrentSoc(connectordetail.current_soc)
      if (status === 'Charging') {
        setShowGauge(true);
      } else {
        setShowGauge(false);
      }

    }
  }, [connectordetail]);

  return (
    <>
      <Modal isOpen={isModalOpen} onClose={closeModal} charger={chargerID} connector={connectorID} initialSoc={currentSoc} />
      <ChangeAvailabilityModal isOpen={isModalOpenAvailibility} onClose={closeModalAvailibility} charger={chargerID} connector={connectorID}  />
      <ChangeConfigModal isOpen={isModalOpenChangeConfig} onClose={closeModalChangeConfig} charger={chargerID} connector={connectorID}  />
      <GetConfigModal isOpen={isModalOpenGetConfig} onClose={closeModalGetConfig} charger={chargerID} connector={connectorID}  />
      <UnlockModal isOpen={isModalOpenUnlock} onClose={closeModalUnlock} charger={chargerID} connector={connectorID}  />
      <SendPaymentModal isOpen={isModalOpenPayment} onClose={closeModalPayment} charger={chargerID} connector={connectorID}  />
      <div className="flex-grow p-1 overflow-auto bg-gray-200">
        <div className="container mx-auto p-3">
          {/* <!-- Charts Section --> */}
          <section className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-4">
            {/* <!-- Battery Details --> */}
            <div className="bg-white p-4 rounded-lg shadow-md h-auto">
              <h2 className="text-xl font-semibold mb-2 text-gray-800">
                Battery Details
              </h2>
              <div className="space-y-4">
                {!connectordetail ? (
                  <div>No details available</div>
                ) : (
                  Object.entries(connectordetail).map(([key, value]) => {
                    // Render only if the key is not 'current_soc'
                    if (key !== 'dd') {
                      return (
                        <div key={key} className="flex justify-between py-.1">
                          <span className="font-medium text-gray-700">
                            {key.toUpperCase()}
                          </span>
                          <span className="text-gray-500">{value}</span>
                        </div>
                      );
                    }
                    return null; // Ensure to return null if the key is 'current_soc'
                  })
                )}
              </div>
              {/* action button */}
              <div className="p-4 space-y-2">
  <div className="grid grid-cols-2 gap-2">
    <button
      onClick={openModal}
      className="bg-green-500 text-white text-xs font-semibold py-1 px-2 rounded hover:bg-green-600 transition-colors"
    >
      Start Transaction
    </button>
    <button
     onClick={openModalUnlock}
      className="bg-blue-500 text-white text-xs font-semibold py-1 px-2 rounded hover:bg-blue-600 transition-colors"
    >
      Unlock Connector
    </button>
    <button
    onClick={openModalPayment}
      className="bg-red-500 text-white text-xs font-semibold py-1 px-2 rounded hover:bg-red-600 transition-colors"
    >
      Send Payment Info
    </button>
    <button
    onClick={openModalGetConfig}
      className="bg-yellow-500 text-white text-xs font-semibold py-1 px-2 rounded hover:bg-yellow-600 transition-colors"
    >
      Get Configuration
    </button>
    <button
    onClick={openModalChangeConfig}
      className="bg-purple-500 text-white text-xs font-semibold py-1 px-2 rounded hover:bg-purple-600 transition-colors"
    >
      Change Configuration
    </button>
    <button
    onClick={openModalAvailibility}
      className="bg-gray-500 text-white text-xs font-semibold py-1 px-2 rounded hover:bg-gray-600 transition-colors"
    >
      Change Availability
    </button>
  </div>
</div>

            </div>

            {/* <!-- State of Charge (SoC) Chart --> */}
            {/* <h2 className="text-xl font-semibold text-gray-800">State of Charge (SoC)</h2> */}
            {/* Render the DialGauge component directly */}
            {showGauge ? (
              <DialGauge value={value} />
            ) : (isModalOpen||isModalOpenAvailibility||isModalOpenChangeConfig||isModalOpenGetConfig||isModalOpenUnlock||isModalOpenPayment) ? (
              <div></div>
            ) : (
              <DialGauge value={null} />
            )}

          </section>

          {/* <!-- Battery Details and Transaction Table --> */}
          {/* <section className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4"> */}
          {/* <!-- Current and Voltage Chart --> */}
          {/* <div className="bg-white p-4 rounded-lg shadow-md h-[400px]">
              <h2 className="text-xl font-semibold mb-2 text-gray-800">
                Current Phase L1 N
              </h2>
              <div className="w-full h-full">
                <RealTimeLineChart val={CurrentVal} time={timestamp_current} />
              </div>
            </div> */}

          {/* <!-- Voltage Chart --> */}
          {/* <div className="bg-white p-4 rounded-lg shadow-md h-[400px]">
              <h2 className="text-xl font-semibold mb-2 text-gray-800">
                Voltage Phase L1 N
              </h2>
              <div className="w-full h-full">
                <RealTimeLineChart val={VoltageVal} time={timestamp_voltage} />
              </div>
            </div> */}
          {/* </section> */}

          {/* <!-- Transactions Table --> */}
          <Transaction stopTransactionvalue ={stopTransactionvalue} charger={chargerID} connector={connectorID} />
        </div>
        {isShowMask && (
          <SweetAlert
            title={message}
            onConfirm={onCancel}
            onCancel={onCancel}
          />
        )}
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  loading: state.loading,
  cpdata: state.cpdata,
  connectordetail: state.connectordetail,
  chargingDetail: state.chargingDetail,
  metervaluedata: state.metervaluedata,
  startTransactionvalue: state.startTransaction,
  stopTransactionvalue: state.stopTransaction,
  getConfigValue:state.getConfig,
  sendPaymentValue:state.sendPayment,
  changeAvailabilityValue:state.changeAvailability,
  changeConfigurationValue:state.changeConfiguration,
  unlockConnectorValue:state.unlockConnector
});

const mapDispatchToProps = (dispatch) => ({
  loader_id: (id) => dispatch(actions.loader(id)),
  getConnectorOnLoad: (chargerID, connectorID) =>
  dispatch(getConnectorOnLoad(chargerID, connectorID)),
  getCpDataOnLoad: () => dispatch(getCpDataOnLoad()),
  updateSendStarTransactionValue: (data) => dispatch(sendStarTransaction(data)),
  updateSendStopTransactionValue: (data) => dispatch(sendStopTransaction(data)),
  updateChangeAvailabilityValue: (data) => dispatch(changeAvailability(data)),
  updateChangeConfigValue: (data) => dispatch(changeConfiguration(data)),
  updateGetConfigValue: (data) => dispatch(getConfig(data)),
  updateUnlockValue: (data) => dispatch(unlockConnector(data)),
  updateSendPaymentValue: (data) => dispatch(sendPayment(data)),
  getTransactionOnLoad: (chargerID, connectorID) =>
  dispatch(getTransactionOnLoad(chargerID, connectorID)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Connector);


// // ###websocke logic start
// // let parsedChargingDetail = {};
// // let connector = "N/A"; // Default to 'N/A'
// // let type = "N/A"; // Default to 'N/A'
// // let value = "N/A"; // Default to 'N/A'
// // let timestamp = "N/A"; // Default to 'N/A'

// // try {
// //   parsedChargingDetail = JSON.parse(chargingDetail);
// // } catch (error) {
// //   console.error("Error parsing chargingDetail:", error);
// // }

// // Check if parsedChargingDetail is an object and not null
// // if (parsedChargingDetail && typeof parsedChargingDetail === "object") {
// //   // Destructure the parsedChargingDetail object with default values
// //   const {
// //     connector: conn = "N/A", // Destructure and alias to avoid name collision
// //     type: typ = "N/A", // Destructure and alias to avoid name collision
// //     data = {}, // Provide a default empty object to avoid issues with data
// //   } = parsedChargingDetail;

// //   // Use optional chaining to safely access properties of data
// //   value = data?.value ?? "N/A"; // Default to 'N/A' if value is undefined
// //   timestamp = data?.timestamp ?? "N/A"; // Default to 'N/A' if timestamp is undefined

// //   // Assign destructured values to component variables
// //   connector = conn;
// //   type = typ;
// // } else {
// //   console.error(
// //     "parsedChargingDetail is not a valid object:"
// //   );
// // }
// // websocket logic end





