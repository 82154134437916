// src/components/DialGauge.js

import React from 'react';
import Plot from 'react-plotly.js';

const DialGauge = ({ value }) => {
  // Ensure value is within the expected range
  const validatedValue = Math.max(0, Math.min(100, value));

  const data = [{
    type: 'indicator',
    mode: 'gauge+number',
    value: validatedValue,
    title: { text: "Dial Gauge", font: { size: 20, color: 'black' } },
    gauge: {
      axis: { 
        range: [0, 100], 
        tickwidth: 2, 
        tickcolor: 'blue' 
      },
      bar: { 
        color: 'black', 
        thickness: 0.2, // Adjust thickness for needle effect
        opacity: 1 // Ensure bar is fully opaque
      },
      bgcolor: 'white',
      borderwidth: 2,
      bordercolor: 'gray',
      steps: [
        { range: [0, 20], color: 'red' },
        { range: [20, 40], color: 'lightcoral' },
        { range: [40, 60], color: 'lightsalmon' },
        { range: [60, 80], color: 'lightyellow' },
        { range: [80, 100], color: 'lightgreen' },
      ],
      threshold: {
        line: { color: 'blue', width: 4 },
        thickness: 0.5,
        value: validatedValue
      }
    }
  }];

  const layout = {
    autosize: true,
    paper_bgcolor: 'white',
    plot_bgcolor: 'white',
    margin: { t: 0, b: 0, l: 0, r: 0 },
    xaxis: { visible: true },
    yaxis: { visible: true },
  };

  return (
    
    <div className="bg-white p-4 rounded-lg shadow-md h-auto  flex items-center justify-center">
      
      <Plot
        data={data}
        layout={layout}
        style={{ width: '75%', height: '75%' }}
      />
    </div>
  );
};

export default DialGauge;
