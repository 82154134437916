import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import {thunk} from 'redux-thunk'; // Import thunk middleware
import reducer from '../store/reducers/auth'; // Update to your actual reducer path
import webSocketMiddleware from  './webSocketMiddleware'; // Import your WebSocket middleware

// Redux Persist configuration
const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducer);

// Create the Redux store with middleware and Redux Persist
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable serializableCheck for Redux Persist
    }).concat(thunk, webSocketMiddleware), // Add thunk middleware
  devTools: process.env.NODE_ENV !== 'production', // Enable Redux DevTools in development
});
// devTools:  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),

export const persistor = persistStore(store);

export default { store, persistor };
