import * as actionTypes from './actionTypes';

// Connect to a WebSocket endpoint
// Connect to a WebSocket endpoint with a specific URL
export const connectWebSocket = (url) => ({

    
    type: actionTypes.WEBSOCKET_CONNECT,
    payload: { url }
  });
  
  // Disconnect from a WebSocket endpoint with a specific URL
  export const disconnectWebSocket = (url) => ({
    type: actionTypes.WEBSOCKET_DISCONNECT,
    payload: { url }
  });
  
  // Action for receiving a message from a specific WebSocket endpoint
  export const receiveMessage = (url, message) => ({
    type: actionTypes.WEBSOCKET_MESSAGE,
    payload: { url, message }
  });
  
  // Action for WebSocket errors from a specific WebSocket endpoint
  export const websocketError = (url, error) => ({
    type: actionTypes.WEBSOCKET_ERROR,
    payload: { url, error }
  });
  
  // Reconnect WebSocket (if needed)
  export const reconnectWebSocket = () => ({
    type: actionTypes.WEBSOCKET_RECONNECT
  });