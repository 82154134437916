import axios from 'axios';
import * as actionTypes from './actionTypes';
import {loader} from './auth';

// User Get Data
export const sendStopTransaction = response => {
    return {
        type: actionTypes.STOP_TRANSACTION,
        payload: response
    }
}

export const sendStopTransactionData = (session) => {
        debugger;
        const token = localStorage.getItem('token');    return dispatch => {
        var instance = axios.create({
            method: 'post',
            timeout: 60000,
            headers: { 'Authorization': 'Bearer ' + token },
            xsrfHeaderName: "X-CSRFToken"
        });
          instance.post(`${actionTypes.BASE_URL}/api/fast-charger/v1/sessions/${session}/stop`)
            .then(response => {
                dispatch(loader(false))
                if(response.data.message==="No record found."){
                    dispatch(sendStopTransaction(true));
                }else{
                    dispatch(sendStopTransaction(true));
                }
                    
            })
            .catch(error => {
                debugger;
                dispatch(loader(false))
                dispatch(sendStopTransaction(false));
                console.log('Error : ', error.message) 

            })
    }
}
