import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions/auth";
import { getMetervalueLoad } from "../../store/actions/getmetervalue";
import { getTransactionOnLoad } from "../../store/actions/getRemoteTransaction";
import { sendStopTransactionData } from "../../store/actions/stopTransaction";
const Transaction = ({
  loader_id,
  charger,
  connector,
  getTransactionOnLoad,
  getMetervalueLoad,
  metervaluedata,
  transactiondata,
  sendStopTransactionData,
  stopTransactionvalue
}) => {

  const handleStopTransaction =(e)=>{
    debugger
    sendStopTransactionData(e);
  }

  useEffect(() => {
    loader_id(true);
    getTransactionOnLoad(charger, connector);
  }, [getTransactionOnLoad,sendStopTransactionData,sendStopTransactionData,stopTransactionvalue]);


  const formatUtcDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZone: "UTC",
      timeZoneName: "short",
    };
    return date.toLocaleString("en-GB", options); // Adjust locale as needed
  };

  return (
    <div className="bg-white p-3 rounded-lg shadow-md mt-6">
      <h2 className="text-xl font-bold mb-2 text-gray-800">
        Transaction History
      </h2>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Date
              </th>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Charger
              </th>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Gun
              </th>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Session
              </th>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Transaction Id
              </th>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Initial Soc
              </th>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Target Soc
              </th>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Final Soc
              </th>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Meter Start
              </th>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Meter End
              </th>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Amount
              </th>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Status
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {transactiondata &&
              transactiondata.map((inner, index) => (
                <tr key={index + 1}>
                  <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                    {formatUtcDate(inner.started_at)}
                  </td>
                  <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                    {inner.chargerID}
                  </td>
                  <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                    {inner.connectorID}
                  </td>
                  <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                    {inner.sessionID}
                  </td>
                  <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                    {inner.id}
                  </td>
                  <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                    {inner.initialSoc}
                  </td>
                  <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                    {inner.targetSoc}
                  </td>
                  <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                    {inner.finalSoc}
                  </td>
                  <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                    {inner.meterestart}
                  </td>
                  <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                    {inner.meterend}
                  </td>
                  <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                    {inner.deducted}
                  </td>
                  <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                    {inner.status}

                    
                    {inner.status === 'STARTED' && (
                          <button onClick={(event) => {
                            event.preventDefault(); // Prevent default action
                            handleStopTransaction(inner.sessionID); // Pass the name
                          }} className="ml-2 px-1 py-0.5 text-xs text-white bg-red-600 rounded hover:bg-red-700">
                            Stop Transaction
                          </button>
                        )}


                          </td>
                </tr>
              ))}

            {/* <!-- More rows as needed --> */}
          </tbody>
        </table>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  loading: state.loading,
  metervaluedata: state.metervaluedata,
  transactiondata: state.transactiondata,
});

const mapDispatchToProps = (dispatch) => ({
  loader_id: (id) => dispatch(actions.loader(id)),
  getTransactionOnLoad: (chargerID, connectorID) =>
    dispatch(getTransactionOnLoad(chargerID, connectorID)),
  getMetervalueLoad: (transactionid) =>
    dispatch(getMetervalueLoad(transactionid)),
  sendStopTransactionData: (sessionId) =>
    dispatch(sendStopTransactionData(sessionId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Transaction);
