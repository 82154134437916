// export const BASE_URL = 'http://127.0.0.1:8000';
export const BASE_URL ='https://fastcharger-dev.spiroiothub.com'

// export const LIST_CHARGER ='/ws/ocppj/listcharger/';
// export const CHARGING_DETAIL ='/ws/ocppj/chargingdetail/';
// export const URL_REMOTE_START= `${actionTypes.BASE_URL}/fast-charger/sessions/start`;
// export const URL_REMOTE_STOP = `${actionTypes.BASE_URL}/fast-charger/sessions/transact352351/stop`;
// export const URL_LIST_TRANSACTION = `${actionTypes.BASE_URL}/fast-charger/sessions/chargers`;

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT' ;
export const registration = 'registration' ;
export const reg_error = 'reg_error' ;
export const LOADER = 'LOADER';

// Api Data
export const GET_CP_DATA = 'GET_CP_DATA';
export const GET_CONNECTOR_DATA = 'GET_CONNECTOR_DATA';
export const GET_REMOTE_TRANSACTION = 'GET_REMOTE_TRANSACTION';
export const GET_METERVALUE = 'GET_METERVALUE';
export const START_TRANSACTION = 'START_TRANSACTION';
export const STOP_TRANSACTION = 'STOP_TRANSACTION';
export const SEND_PAYMENT ='SEND_PAYMENT';
export const CHANGE_CONFIGURATION ='CHANGE_CONFIGURATION';
export const UNLOCK_CONNECTOR ='UNLOCK_CONNECTOR';
export const CHANGE_AVAILABILITY ='CHANGE_AVAILABILITY';
export const GET_CONFIG ='GET_CONFIG'
// Websocket 
// actions/actionTypes.js
export const WEBSOCKET_CONNECT = 'WEBSOCKET_CONNECT';
export const WEBSOCKET_DISCONNECT = 'WEBSOCKET_DISCONNECT';
export const WEBSOCKET_MESSAGE = 'WEBSOCKET_MESSAGE';
export const WEBSOCKET_ERROR = 'WEBSOCKET_ERROR';
export const WEBSOCKET_RECONNECT = 'WEBSOCKET_RECONNECT';
