import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import BaseRouter from './route/route';
import { connect } from 'react-redux';
import * as actions from './store/actions/auth';

const App = ({ onTryAutoSignup }) => {
  // Equivalent of componentDidMount
  useEffect(() => {
    onTryAutoSignup();
  }, [onTryAutoSignup]); 

  return (
    <> {/* Changed class name to something more meaningful */}
      <Router>
        <BaseRouter />
      </Router>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.isAuthenticated,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(App);


