import axios from 'axios';
import * as actionTypes from './actionTypes';
import {loader} from './auth';

// User Get Data
const getConnector = response => {
    return {
        type: actionTypes.GET_CONNECTOR_DATA,
        payload: response
    }
}

export function getConnectorOnLoad(charger, gun) {
    const token = localStorage.getItem('token');
var url = `${actionTypes.BASE_URL}/api/fast-charger/v1/chargers/${charger}/connector/${gun}`;
    return function (dispatch) {
        return axios({
            method: 'GET',
            url: url,
            headers: { 'Authorization': 'Bearer ' + token },
            xsrfHeaderName: "X-CSRFToken"
        })
            .then(response => {
                dispatch(loader(false))
                if(response.data.message==="No record found."){
                    dispatch(getConnector(response.data.message));
                }else{
                    dispatch(getConnector(response.data.data));
                }
            })
            .catch(error => {
                dispatch(loader(false))
                 console.log('Error : ', error.message) });
    };
}


