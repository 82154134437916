import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import Dashboard from "../Dashboard/dashboard";

const Layout = (Component) => ({ ...props }) => (
    <>
      <div className="flex w-screen h-screen bg-white text-gray-800">
        <Sidebar />
        <div className="flex flex-col flex-grow">
          <Header />
          <Component {...props} />
        </div>
        {/* Component End */}
      </div>
    </>

);

export default Layout;
