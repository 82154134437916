import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import * as actions from "../store/actions/auth";
import { sendPaymentData } from '../store/actions/send_payment';
const SendPaymentInfo = ({ loader_id,sendPaymentData, isOpen, onClose, charger, connector }) => {

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    const balance = e.target.elements.balance.value;; // Get the value of Target Soc
    const deduction = e.target.elements.deduction.value;; // Get the value of Target Soc
    const data = {
      connector: Number(connector),
      balance:Number(balance),
      deduction:Number(deduction)
    };
    console.log(data);
    loader_id(true);
    sendPaymentData(charger,connector,data)
    onClose()

  };
  if (!isOpen) return null;

  return (

    <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center">

      <div className="bg-white p-6 rounded-lg shadow-lg">
        <form className="space-y-4" onSubmit={handleSubmit}>
          <div className="flex items-center mb-4">
            <label className="block text-sm mb-1 w-1/3">Charger:</label>
            <input
              type="text"
              defaultValue={charger}
              className="border rounded w-2/3 px-2 py-1 bg-gray-100 cursor-not-allowed" required
              readOnly
            />
          </div>
          <div className="flex items-center mb-4">
            <label className="block text-sm mb-1 w-1/3">Connector:</label>
            <input
              defaultValue={connector}
              type="text"
              className="border rounded w-2/3 px-2 py-1 bg-gray-100 cursor-not-allowed" required
              readOnly
            />
          </div>
          <div className="flex items-center mb-4">
            <label className="block text-sm mb-1 w-1/3">Deduction:</label>
            <input
              type="number"
              name= "deduction"
              className="border rounded w-2/3 px-2 py-1 bg-gray-100 cursor-not-allowed" // Change background color
              required
            />
          </div>
          <div className="flex items-center mb-4">
            <label className="block text-sm mb-1 w-1/3">Balance</label>
            <input
              type="number"
              className="border rounded w-2/3 px-2 py-1"
              required
              name="balance"
            />
          </div>

          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Submit
          </button>
        </form>


        <button onClick={onClose} className="mt-4 text-red-500">
          Close
        </button>
      </div>

    </div>
  );
};


const mapStateToProps = (state) => ({
  loading: state.loading,
  sendPaymentvalue: state.sendPayment,

});

const mapDispatchToProps = (dispatch) => ({
  loader_id: (id) => dispatch(actions.loader(id)),
  sendPaymentData: (charger,connector,data) =>
    dispatch(sendPaymentData(charger,connector,data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SendPaymentInfo);
