import React from "react";
import { connect } from 'react-redux';
import * as actions from '../../store/actions/auth';
import { disconnectWebSocket } from '../../store/actions/webscket'; // Adjust path as necessary
import * as actionTypes from '../../store/actions/actionTypes'
const Logout =({logouts,disconnect_ws})=>{
 
  const signout = () => {
    
    localStorage.removeItem('token');
    disconnect_ws(`${actionTypes.BASE_URL}/ws/ocppj/shenrui001/`);
    logouts();
    
  };
    return (
      <button onClick ={signout} class="flex items-center justify-center h-10 px-4 ml-auto text-sm font-medium rounded hover:bg-gray-300">
          Log Out
      </button>
    );
}

//Get Data from Redux Store

const mapStateToProps = (state) => ({
  loading: state.loading
});
//Dispatch Data to Redux Store
const mapDispatchToProps = dispatch => {
  return {
    logouts: () => dispatch(actions.logout()),
    disconnect_ws:(url) =>dispatch(disconnectWebSocket(url)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Logout);