import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    token: null,
    error: null, 
    loading: false,
    regis:null,
    email_link:null,
    isAuthenticated:false,
    cpdata:null,
    listChargers: null,
    chargingDetail:null,
    connectordetail :null,
    transactiondata :null,
    metervaluedata:null,
    startTransaction:null,
    stopTransaction:null,
    changeConfiguration:null,
    changeAvailability:null,
    unlockConnector:null,
    sendPayment:null,
    getConfig:null


    // errorws: null

    
}

const authStart = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: true
    });
}

const authSuccess = (state, action) => {
    return updateObject(state, {
        token: action.token,
        error: null,
        loading: false,
        isAuthenticated:true
    });

}

const authFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
        listChargers: null,
        chargingDetail:null,
        connectordetail :null,
        transactiondata :null,
        metervaluedata:null,
        startTransaction:null,
        stopTransaction:null,
        changeConfiguration:null,
        changeAvailability:null,
        unlockConnector:null,
        sendPayment:null

    });
}

const authLogout = (state, action) => {

    return updateObject(state, {
        token: null,
        error: null, 
        loading: false,
        regis:null,
        email_link:null,
        isAuthenticated:false,
        cpdata:null,
        listChargers: null,
        chargingDetail:null,
        connectordetail :null,
        transactiondata :null,
        metervaluedata:null,
        startTransaction:null,
        stopTransaction:null,
        changeConfiguration:null,
        changeAvailability:null,
        unlockConnector:null,
        sendPayment:null

    });
}

const registration = (state, action) => {
    return updateObject(state, {
        email_link: action.email_link,
        error: null,
        loading: false
    });
}

const reg_error = (state, action) => {
    return updateObject(state, {
        regis: action.regis,
        loading: false
    });
}



const LOADER = (state, action) => {
    return updateObject(state, {
        loading: action.loading
    });
}


const WEBSOCKET_MESSAGE = (state, action) => {
    const { url, message } = action.payload;
    if (url==='http://127.0.0.1:8000/ws/ocppj/shenrui001/'){
        return updateObject(state,{
            chargingDetail:message
        })
    }else{
        return updateObject(state,{
            listChargers:message
        })
    }
    // return updateObject(state, {
    //     messages: {
    //         ...state.messages,
    //         [url]: [...(state.messages[url] || []), message]
    //     }
    // });
};

// Handle WebSocket errors
const WEBSOCKET_ERROR = (state, action) => {
    const { url, error } = action.payload;
    return updateObject(state, {
        errors: {
            ...state.errors,
            [url]: error
        }
    });
};

const reducer = (state=initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
        case actionTypes.registration: return registration(state, action);
        case actionTypes.reg_error: return reg_error(state, action);
        case actionTypes.LOADER: return LOADER(state, action);
        case actionTypes.WEBSOCKET_MESSAGE:
            return WEBSOCKET_MESSAGE(state, action);
        case actionTypes.WEBSOCKET_ERROR:
            return WEBSOCKET_ERROR(state, action);
        case actionTypes.GET_CP_DATA: {
            return {
                ...state,
                cpdata: action.payload,
            };
        }
        case actionTypes.GET_CONNECTOR_DATA: {
            return {
                ...state,
                connectordetail: action.payload,
            };
        }
        case actionTypes.GET_METERVALUE: {
            return {
                ...state,
                metervaluedata: action.payload,
            };
        }
        case actionTypes.GET_REMOTE_TRANSACTION: {
            return {
                ...state,
                transactiondata: action.payload,
            };
        }
        case actionTypes.START_TRANSACTION: {
            return {
                ...state,
                startTransaction: action.payload,
            };
        }
        case actionTypes.STOP_TRANSACTION: {
            return {
                ...state,
                stopTransaction: action.payload,
            };
        }
        case actionTypes.SEND_PAYMENT: {
            return {
                ...state,
                sendPayment: action.payload,
            };
        }
        case actionTypes.CHANGE_CONFIGURATION: {
            return {
                ...state,
                changeConfiguration: action.payload,
            };
        }
        case actionTypes.UNLOCK_CONNECTOR: {
            return {
                ...state,
                unlockConnector: action.payload,
            };
        }
        case actionTypes.CHANGE_AVAILABILITY: {
            return {
                ...state,
                changeAvailability: action.payload,
            };
        }
        case actionTypes.GET_CONFIG: {
            return {
                ...state,
                getConfig: action.payload,
            };
        }
        default:
            return state;
    }
}

export default reducer;
