import { receiveMessage } from './actions/webscket';
class WebSocketManager {
    constructor() {
        if (!WebSocketManager.instance) {
            this.connections = {}; // Track multiple WebSocket connections
            this._reconnectInterval = 1000; // Initial reconnect interval
            this._maxReconnectInterval = 10000; // Maximum reconnect interval
            WebSocketManager.instance = this;
        }
        return WebSocketManager.instance;
    }

    connect(url, onMessage, onError,dispatch) {
        if (this.connections[url]) {
            console.log(`WebSocket connection already exists for ${url}`);
            return;
        }

        const token = localStorage.getItem('token');
        if (!token) {
            console.log('Token is no longer valid. WebSocket connection will not be established.');
            return;
        }

        const ws = new WebSocket(url);

        ws.onopen = () => {
            console.log(`WebSocket connection opened for ${url}`);
        };

        ws.onmessage = (event) => {
            if (onMessage) onMessage(event.data, url);
            // console.log(`Message received from ${url}:`, event);          
        };

        ws.onerror = (error) => {
            if (onError) onError(error, url);
            console.error(`WebSocket error for ${url}:`, error);
        };

        ws.onclose = (event) => {
            console.log(`WebSocket connection closed for ${url}`, event);
            // debugger;
            if (this.connections[url]){
                debugger;
                delete this.connections[url];
            };
            this.handleReconnect(url);
        };

        this.connections[url] = { ws, onMessage, onError };
    }

    handleReconnect(url) {
        const connection = this.connections[url];
        if(typeof connection !=='undefined'){
            if (!connection || connection.ws.readyState !== WebSocket.CLOSED) {
                console.log("return on above two")
                // debugger
                return;
            }
    }

        const token = localStorage.getItem('token');
        if (token) {
            console.log(`Attempting to reconnect to ${url}...`);
            setTimeout(() => {
                // debugger;
                this.connect(url);
                // Increase reconnect interval up to a maximum limit
                this._reconnectInterval = Math.min(this._reconnectInterval * 2, this._maxReconnectInterval);
                console.log(this._reconnectInterval)
            }, this._reconnectInterval);
        } else {
            console.log('Token is no longer valid. Cannot reconnect.');
        }
    }

    sendMessage(url, message) {
        const connection = this.connections[url];
        if (connection && connection.ws.readyState === WebSocket.OPEN) {
            connection.ws.send(message);
        } else {
            console.error(`WebSocket is not open for ${url}. Message not sent.`);
        }
    }

    closeConnection(url) {
        const connection = this.connections[url];
        debugger;
        console.log(connection)
        if (connection && connection.ws) {
            const { ws } = connection;
            if (ws.readyState === WebSocket.OPEN || ws.readyState === WebSocket.CLOSING) {
                ws.close();
            } else {
                console.warn('WebSocket is already closed or not properly initialized. ReadyState:', ws.readyState);
            }
            console.log(`WebSocket connection closed manually for ${url}`);
        } else {
            console.error('No WebSocket connection found for URL:', url);
        }
        if (this.connections[url]){
            delete this.connections[url];
        };
    }
}

export default WebSocketManager;
