import axios from 'axios';
import * as actionTypes from './actionTypes';
import {loader} from './auth';
import { getMetervalueLoad } from './getmetervalue';
// User Get Data
const getTransactionData = response => {
    return {
        type: actionTypes.GET_REMOTE_TRANSACTION,
        payload: response
    }
}

export function getTransactionOnLoad(charger,connector) {
    const token = localStorage.getItem('token');
    var url = `${actionTypes.BASE_URL}/api/fast-charger/v1/transaction/${charger}/${connector}`
    return function (dispatch) {
        return axios({
            method: 'GET',
            url: url,
            headers: { 'Authorization': 'Bearer ' + token },
            xsrfHeaderName: "X-CSRFToken"
        })
            .then(response => {
                dispatch(loader(false))
                if(response.data.message==="No record found."){
                    dispatch(getTransactionData(response.data.message));
                }else{
                    dispatch(getTransactionData(response.data.data));
                    let transactiondata =response.data.data;
                        if (Array.isArray(transactiondata) && transactiondata.length > 0){
                            dispatch(getMetervalueLoad(transactiondata[0].id));
                        }
                }
            })
            .catch(error => {
                dispatch(loader(false))
                 console.log('Error : ', error.message) });
    };
}


