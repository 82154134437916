import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/auth';
import Loader from '../Loader/Loader';
import SweetAlert from 'react-bootstrap-sweetalert';
import './Login.css'; // Import the CSS file

const Login = ({ onAuth, error, loading, isAuthenticated, connect_ws,disconnect_ws }) => {
  const [inputValue, setInputValue] = useState({ email: "", password: "" });
  const [isShowMask, setShowMask] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const onCancel = () => {
    setShowMask(false);
  };

  const onSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission
    onAuth(inputValue.email, inputValue.password);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  
  useEffect(() => {
    if (isAuthenticated) {
      // connect_ws('ws://127.0.0.1:8000/ws/ocppj/shenrui001/');
      // connect_ws('ws://127.0.0.1:8000/ws/ocppj/shenrui002/');
      navigate("/dashboard");
    }
  }, [isAuthenticated, navigate,connect_ws,disconnect_ws]);


  useEffect(() => {
    if (error) {
      const msg = error?.response?.data?.detail || "An error occurred.";
      setErrorMessage(msg);
      setShowMask(true);
    }
  }, [error]);

  return (
    <div className="login-container">
     
      {loading && <Loader />}
      <div className="login-form-container">
        <form className="login-form" onSubmit={onSubmit}>
          <h2 className="login-title">CSMS FASTCHARGER</h2>
          <div className="input-group">
            <label htmlFor="email" className="input-label">Email</label>
            <input
              type="text"
              value={inputValue.email}
              placeholder="Enter your email"
              name="email"
              onChange={handleChange}
              className="input-field"
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="password" className="input-label">Password</label>
            <input
              type="password"
              value={inputValue.password}
              placeholder="Enter your password"
              name="password"
              onChange={handleChange}
              className="input-field"
              required
            />
          </div>
          <button type="submit" className="submit-button" disabled={loading}>
            {loading ? 'Logging in...' : 'Login'}
          </button>
        </form>
      </div>
      {isShowMask && (
        <SweetAlert
          title={error}
          onConfirm={onCancel}
          onCancel={onCancel}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.loading,
  error: state.error,
  isAuthenticated: state.isAuthenticated,
});

const mapDispatchToProps = (dispatch) => ({
  onAuth: (email, password) => dispatch(actions.authLogin(email, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
