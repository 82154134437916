import React, { useEffect, useState } from "react";
import * as actions from "../../store/actions/auth";
import { connect } from "react-redux";
import Loader from "../Loader/Loader";
import { getCpDataOnLoad } from "../../store/actions/cp";
import { NavLink } from "react-router-dom";

const Dashboard = ({ getCpDataOnLoad, cpdata, loader_id, loading }) => {
  const [activeCount, setActiveCount] = useState(0);
  const [inactiveCount, setInactiveCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [guncount, setGunCount] = useState(0);
  const [incharging, setIncharging] = useState(0);
  const cardData = [
    {
      title: 'Total Charging Points',
      value: totalCount,
      textColor: 'text-gray-800',
    },
    {
      title: 'Active Charging Points',
      value: activeCount,
      textColor: 'text-green-600',
    },
    {
      title: 'InActive Charging Points',
      value: inactiveCount,
      textColor: 'text-red-600',
    },
    {
      title: 'Available Gun',
      value: guncount,
      textColor: 'text-green-600',
    },
    {
      title: 'Gun in Charging State',
      value: incharging,
      textColor: 'text-red-600',
    },
  ];


  useEffect(() => {
    loader_id(true)
    getCpDataOnLoad();
    const intervalId = setInterval(() => {
      getCpDataOnLoad();
  }, 10000); // 10000 milliseconds = 10 seconds

  // Clean up the interval on component unmount
  return () => clearInterval(intervalId);
  }, [getCpDataOnLoad]);

  useEffect(() => {
    if (Array.isArray(cpdata)) {
      let tempActiveCount = 0;
      let tempInactiveCount = 0;
      let tempGun = 0;
      let tempIncharging = 0;
      let tempTotalCount = cpdata.length;

      cpdata.forEach(item => {
        if (item.status === 'Active') {
          tempActiveCount++;
        } else if (item.status === 'InActive') {
          tempInactiveCount++;
        }
        if (item.connectors) {
          item.connectors.forEach(connector => {
              if (connector.status === 'Available') {
                tempGun++;
              }else if (connector.status === 'Charging'){
                tempIncharging++
              }
          });
      }
      });

      setActiveCount(tempActiveCount);
      setInactiveCount(tempInactiveCount);
      setTotalCount(tempTotalCount);
      setGunCount(tempGun);
      setIncharging(tempIncharging);
    }
  }, [cpdata]);

 
  return (
    <>
      {loading && <Loader />}

          <div className="flex-grow p-1 overflow-auto bg-gray-200">
            <div className="container mx-auto p-1">
              {/* Stats Section */}
              <section className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-5 gap-1 mb-2">
              {cardData.map((card, index) => (
                          <div
                            key={index +1}
                            className="card bg-white p-3 rounded-lg shadow-lg text-center"
                          >
                            <h3 className="text-sm font-semibold mb-1">{card.title}</h3>
                            <p className={`text-xl font-bold ${card.textColor}`}>
                              <strong>{card.value}</strong>
                            </p>
                          </div>
                        ))}
              </section>
              {/* Fastcharger List Table */}
              <section>
              {/* <div className="flex justify-end">
                  <button
                    id="add-fastcharger-btn"
                    className="bg-blue-500 text-white py-1 px-2 rounded-lg shadow hover:bg-blue-600 transition text-xs  mt-2 mb-2"
                  >
                    Add Fastcharger
                  </button>
                </div> */}
                <div className="table-container">
                
                  <table
                    id="fastcharger-table"
                    className="w-full bg-white rounded-lg shadow-lg table"
                  >
                    <thead className="bg-blue-700 text-white">
                      <tr>
                        <th className="border-b">S. No</th>
                        <th className="border-b">Name</th>
                        <th className="border-b">Status</th>
                        <th className="border-b">Guns</th>
                        <th className="border-b">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                    {Array.isArray(cpdata) ? (
            cpdata.map((data, index) => (
              <tr key={data.chargerID} className="hover:bg-gray-100 transition">
                <td className="border-b">{index + 1}</td>

                  <td className="border-b"><b>{data.chargerID.toUpperCase()}</b></td>
                <td className={`border-b ${data.status === 'Active' ? 'text-green-600' : 'text-red-600'}`}>
                  {data.status}
                </td>
                <td className="border-b text-xs">
                  {data.connectors &&
                    data.connectors.map((inner, innerIndex) => (
                      
                      <div key={innerIndex+1} className="flex flex-col gap-1">
                        <NavLink 
                        to={{
                          pathname: `/connector/${data.chargerID}/${inner.gun}`, // Route path
                        }}
                        className="block text-blue-600 hover:bg-blue-100 hover:text-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-200 ease-in-out"
                        activeClassName="font-bold text-blue-800"
                        title={`Go to connector ${inner.gun}`}
                        style={{
                          textDecoration: 'none',
                          display: 'block',
                          padding: '0.5rem 1rem',
                          borderRadius: '0.25rem'
                        }}>
                        <p>
                          Gun {inner.gun}:{" "}
                          <span
                            className={`${
                              inner.status === 'Available' ? 'text-green-600' :
                              'text-red-600'
                            }`}
                          >
                            {inner.status}
                          </span>
                        </p>
                        </NavLink>
                      </div>
                    ))}
                </td>
                <td className="border-b text-xs">
                  <div className="flex flex-col gap-1">
                    <button className="bg-red-500 text-white py-1 px-2 rounded-lg shadow hover:bg-red-600 transition text-xs">
                      Delete
                    </button>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" className="text-center py-4">No data available</td>
            </tr>
          )}
                    </tbody>
                  </table>
                </div>

                {/* Action Buttons */}
                
              </section>
            </div>
          </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.loading,
  cpdata: state.cpdata,
});

const mapDispatchToProps = (dispatch) => ({
  loader_id: (id) => dispatch(actions.loader(id)),
  getCpDataOnLoad: () => dispatch(getCpDataOnLoad()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
