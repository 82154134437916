import React from "react";
import {Route, Routes,Navigate  } from 'react-router-dom';
import Login from '../core/Login/Login';
import Loader from "../core/Loader/Loader";
import {isLogin} from './utils1';
import Dashboard from "../core/Dashboard/dashboard";
import Connector from "../core/Connector/connector";
import Layout from "../core/Layout/layout";
const DashboardComponent = Layout(Dashboard)
const ConnectorComponent = Layout(Connector)

const BaseRouter = ({ ...props }) => (
  <Routes>
    <Route path="/" element={<Login />} />
    <Route path="/dashboard" element={<PrivateRoute element={DashboardComponent} />} />  
    <Route path="/connector/:chargerID/:connectorID" element={<PrivateRoute element={ConnectorComponent} />} />  
  </Routes>
);

const PrivateRoute = ({ element: Element, ...rest }) => {
  return isLogin() ? <Element {...rest} /> : <Navigate to="/" />;
};
export default BaseRouter;
