

// import WebSocketManager from './WebSocketManager'; // Adjust path as necessary
// import * as actionTypes from '../store/actions/actionTypes'; // Adjust path as necessary
// import { receiveMessage, websocketError } from '../store/actions/webscket'; // Adjust path as necessary

// const webSocketManager = new WebSocketManager();

// const webSocketMiddleware = (store) => (next) => (action) => {
//     switch (action.type) {
//         case actionTypes.WEBSOCKET_CONNECT:
//             const { url } = action.payload;
//             webSocketManager.connect(url, onMessageCallback, onErrorCallback, dispatch);
//             // webSocketManager.connect(url);
//             break;

//         case actionTypes.WEBSOCKET_DISCONNECT:
//             const disconnectUrl = action.payload.url;
//             webSocketManager.closeConnection(disconnectUrl);
//             break;

//         case actionTypes.WEBSOCKET_RECONNECT:
//             // Handle reconnect logic if needed
//             break;

//         default:
//             break;
//     }

//     return next(action);
// };

// export default webSocketMiddleware;

import WebSocketManager from './WebSocketManager'; // Adjust path as necessary
import * as actionTypes from '../store/actions/actionTypes'; // Adjust path as necessary
import { receiveMessage, websocketError } from '../store/actions/webscket'; // Adjust path as necessary

const webSocketManager = new WebSocketManager();

const webSocketMiddleware = (store) => (next) => (action) => {
    const { dispatch } = store;

    // Define your message and error handlers
    const onMessageCallback = (data, url) => {
        // Dispatch an action with the received message
        dispatch(receiveMessage(url, data));
    };

    const onErrorCallback = (error, url) => {
        // Dispatch an action with the WebSocket error
        dispatch(websocketError(url, error));
    };

    switch (action.type) {
        case actionTypes.WEBSOCKET_CONNECT:
            const { url } = action.payload;
            webSocketManager.connect(url, onMessageCallback, onErrorCallback, dispatch);
            break;

        case actionTypes.WEBSOCKET_DISCONNECT:
            const disconnectUrl = action.payload.url;
            webSocketManager.closeConnection(disconnectUrl);
            break;

        case actionTypes.WEBSOCKET_RECONNECT:
            // Handle reconnect logic if needed
            break;

        default:
            break;
    }

    return next(action);
};

export default webSocketMiddleware;
