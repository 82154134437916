import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import * as actions from "../store/actions/auth";
import { unlockConnectorData } from '../store/actions/unlockConnector';
const UnlockModal = ({ loader_id,unlockConnectorData, isOpen, onClose, charger, connector }) => {

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    unlockConnectorData(charger, connector);
    onClose()

  };
  if (!isOpen) return null;

  return (

    <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center">

      <div className="bg-white p-6 rounded-lg shadow-lg">
        <form className="space-y-4" onSubmit={handleSubmit}>
          <div className="flex items-center mb-4">
            <label className="block text-sm mb-1 w-1/3">Charger:</label>
            <input
              type="text"
              defaultValue={charger}
              className="border rounded w-2/3 px-2 py-1 bg-gray-100 cursor-not-allowed" required
              readOnly
            />
          </div>
          <div className="flex items-center mb-4">
            <label className="block text-sm mb-1 w-1/3">Connector:</label>
            <input
              defaultValue={connector}
              type="text"
              className="border rounded w-2/3 px-2 py-1 bg-gray-100 cursor-not-allowed" required
              readOnly
            />
          </div>

          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Submit
          </button>
        </form>


        <button onClick={onClose} className="mt-4 text-red-500">
          Close
        </button>
      </div>

    </div>
  );
};


const mapStateToProps = (state) => ({
  loading: state.loading,
  unlockConnector: state.unlockConnector,

});

const mapDispatchToProps = (dispatch) => ({
  loader_id: (id) => dispatch(actions.loader(id)),
  unlockConnectorData: (charger, connector) =>
    dispatch(unlockConnectorData(charger, connector)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UnlockModal);
