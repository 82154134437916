import axios from 'axios';
import * as actionTypes from './actionTypes';
import {loader} from './auth';

// User Get Data
export const sendStarTransaction = response => {
    return {
        type: actionTypes.START_TRANSACTION,
        payload: response
    }
}

export const sendStarTransactionData = (data) => {
    console.log('sendStarTransactionData call')
    const token = localStorage.getItem('token');
    debugger;
    return dispatch => {
        var instance = axios.create({
            method: 'post',
            timeout: 60000,
            headers: { 'Authorization': 'Bearer ' + token },
            xsrfHeaderName: "X-CSRFToken"
        });
          instance.post(`${actionTypes.BASE_URL}/api/fast-charger/v1/sessions/start`, {
            sessionID:data['sessionID'], 
            chargerID: data['chargerID'], 
            connectorID: data['connectorID'], 
            initialSoc: data['initialSoc'],  
            targetSoc: data['targetSoc'],
        })
            .then(response => {
                debugger;
                dispatch(loader(false))
                if(response.data.message==="No record found."){
                    dispatch(sendStarTransaction(true));
                }else{
                    dispatch(sendStarTransaction(true));
                }
                    
            })
            .catch(error => {
                debugger;
                dispatch(loader(false))
                dispatch(sendStarTransaction(false));
                console.log('Error : ', error.message) 

            })
    }
}
