import axios from 'axios';
import * as actionTypes from './actionTypes';
import {loader} from './auth';

// User Get Data
export const sendPayment = response => {
    return {
        type: actionTypes.SEND_PAYMENT,
        payload: response
    }
}

export const sendPaymentData = (charger,trasactionId,data) => {
    const token = localStorage.getItem('token');
    return dispatch => {
        var instance = axios.create({
            method: 'post',
            timeout: 60000,
            headers: { 'Authorization': 'Bearer ' + token },
            xsrfHeaderName: "X-CSRFToken"
        });
          instance.post(`${actionTypes.BASE_URL}/api/fast-charger/v1/payment/chargers/${charger}/${trasactionId}`, {
            connector:data['connector'],
            balance:data['balance'],
            deduction:data['deduction']
        })
            .then(response => {
                dispatch(loader(false))
                if(response.data.message==="No record found."){
                    dispatch(sendPayment(true));
                }else{
                    dispatch(sendPayment(true));
                }
                    
            })
            .catch(error => {
                dispatch(loader(false));
                dispatch(sendPayment(false));
                console.log('Error : ', error.message) 

            })
    }
}
