import axios from 'axios';
import * as actionTypes from './actionTypes';
import {loader} from './auth';

// User Get Data
export const getConfig = response => {
    return {
        type: actionTypes.GET_CONFIG,
        payload: response
    }
}

export const getConfigData = (charger,connectorid,data) => {
    const token = localStorage.getItem('token');
    return dispatch => {
        var instance = axios.create({
            method: 'post',
            timeout: 60000,
            headers: { 'Authorization': 'Bearer ' + token },
            xsrfHeaderName: "X-CSRFToken"
        });
          instance.post(`${actionTypes.BASE_URL}/api/fast-charger/v1/get/configuration/charger/${charger}/connector/${connectorid}`, {
            key: data['key'] 

        })
            .then(response => {
                dispatch(loader(false))
                if(response.data.message==="No record found."){
                    dispatch(getConfig(true));
                }else{
                    dispatch(getConfig(true));
                }
                    
            })
            .catch(error => {
                dispatch(loader(false))
                dispatch(getConfig(false));
                console.log('Error : ', error.message) 

            })
    }
}
