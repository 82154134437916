// src/components/RealTimeLineChart.js

import React, { useState, useEffect } from 'react';
import Plot from 'react-plotly.js';

const RealTimeLineChart = React.memo(({val,time}) => {
  const [data, setData] = useState({
    x: [],
    y: []
  });

  const [layout] = useState({
    // title: 'Real-Time Line Chart',
    xaxis: { title: 'Time' },
    yaxis: { title: 'Value' },
    autosize: true
  });
  


  useEffect(() => {
    console.log('linechart')
    console.log('linechart end')
    const intervalId = setInterval(() => {
      const now = new Date(time);
      // const options = { timeZone: 'UTC', hour: '2-digit', minute: '2-digit', second: '2-digit' };
const newTime = now.toLocaleTimeString(); 
      const newValue = val; // Replace with real-time data
      setData(prevData => {
        const updatedX = [...prevData.x, newTime];
        const updatedY = [...prevData.y, newValue];

        // Keep the last 20 points for real-time effect
        if (updatedX.length > 20) {
          updatedX.shift();
          updatedY.shift();
        }

        return { x: updatedX, y: updatedY };
      });
    }, 1000); // Update every second

    return () => clearInterval(intervalId);
  }, [val, time]);

  return (
    <div className="w-full h-full">
      <Plot
        data={[
          {
            x: data.x,
            y: data.y,
            type: 'scatter',
            mode: 'lines+markers',
            marker: { color: 'blue' },
          }
        ]}
        layout={layout}
        style={{ width: '100%', height: '90%' }}
      />
    </div>
  );
});

export default RealTimeLineChart;
