import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import * as actions from "../store/actions/auth";
import { sendStarTransactionData, sendStarTransaction } from '../store/actions/startTransaction';

const Modal = ({ loader_id, updateSendStarTransactionValue, startTransactionvalue, sendStarTransactionData, isOpen, onClose, charger, connector, initialSoc }) => {

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    // Create data object from form inputs
    const timestamp = Math.floor(Date.now()); // This gives you seconds
    const uniqueIdentifier = `${timestamp}${charger}${connector}`;

    const targetSoc = e.target.elements.targetSoc.value;; // Get the value of Target Soc
    const data = {
      sessionID: uniqueIdentifier,
      chargerID: charger,
      connectorID: Number(connector),
      initialSoc: Number(initialSoc),
      targetSoc: Number(targetSoc),
    };
    console.log(data);
    loader_id(true);
    sendStarTransactionData(data)
    onClose()

  };
  if (!isOpen) return null;

  return (

    <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center">

      <div className="bg-white p-6 rounded-lg shadow-lg">
        <form className="space-y-4" onSubmit={handleSubmit}>
          <div className="flex items-center mb-4">
            <label className="block text-sm mb-1 w-1/3">Charger:</label>
            <input
              type="text"
              defaultValue={charger}
              className="border rounded w-2/3 px-2 py-1 bg-gray-100 cursor-not-allowed" required
              readOnly
            />
          </div>
          <div className="flex items-center mb-4">
            <label className="block text-sm mb-1 w-1/3">Connector:</label>
            <input
              defaultValue={connector}
              type="text"
              className="border rounded w-2/3 px-2 py-1 bg-gray-100 cursor-not-allowed" required
              readOnly
            />
          </div>
          <div className="flex items-center mb-4">
            <label className="block text-sm mb-1 w-1/3">Initial Soc:</label>
            <input
              type="number"
              defaultValue={initialSoc}
              className="border rounded w-2/3 px-2 py-1 bg-gray-100 cursor-not-allowed" // Change background color
              required
              readOnly
            />
          </div>
          <div className="flex items-center mb-4">
            <label className="block text-sm mb-1 w-1/3">Target Soc</label>
            <input
              type="number"
              className="border rounded w-2/3 px-2 py-1"
              required
              name="targetSoc"
            />
          </div>
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Submit
          </button>
        </form>


        <button onClick={onClose} className="mt-4 text-red-500">
          Close
        </button>
      </div>

    </div>
  );
};


const mapStateToProps = (state) => ({
  loading: state.loading,
  startTransactionvalue: state.startTransaction,

});

const mapDispatchToProps = (dispatch) => ({
  updateSendStarTransactionValue: (data) => dispatch(sendStarTransaction(data)),
  loader_id: (id) => dispatch(actions.loader(id)),
  sendStarTransactionData: (data) =>
    dispatch(sendStarTransactionData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
